import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput, AuthContainer } from 'src/Components';
import { EmailValidation } from 'src/Utils/Validation';
import { forgotPassword } from 'src/Redux/Actions';
import { showSpinner, hideSpinner, showToast } from 'src/Utils/Helper';

export default function ForgotPassPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLoadingRef = useRef(false);
  const {
    forgotPass_res, forgotPass_success, forgotPass_loading
  } = useSelector(
    state => state.Auth,
  );

  useEffect(() => {
    if (getLoadingRef.current && !forgotPass_loading) {
      hideSpinner();
      if (forgotPass_success) {
        //Forgot Password Success
        navigate('/auth/new-password');
      } else {
        //Forgot Password Failed
        showToast('error', 3000, forgotPass_res)
      };
    }
    getLoadingRef.current = forgotPass_loading;
  }, [dispatch, forgotPass_res, forgotPass_loading, forgotPass_success, navigate]);

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState('');

  const onChangeEmail = (value) => {
    setEmail(value);
    setEmailValid(value)
  };

  const checkDisableBtn = () => {
    if (EmailValidation(email))
      return false;
    return true;
  }

  const onContinue = () => {
    showSpinner();
    dispatch(forgotPassword({ email }));
  };

  return (
    <AuthContainer>
      <div className="login-component">
        <h1>Forgot Password</h1>
        <FormInput
          placeholder={"Email"}
          className='auth-input'
          value={email}
          validation={emailValid}
          onChange={onChangeEmail}
        />
        <div
          className={`btn-2 continue-btn ${checkDisableBtn() ? 'disable' : ''}`}
          onClick={onContinue}>
          Continue
        </div>
      </div>
    </AuthContainer>
  );
}