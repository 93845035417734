import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { AuthContainer } from 'src/Components';

export default function NewPassPage() {
  const navigate = useNavigate();

  const onContinue = () => {
    navigate('/');
  }
  return (
    <AuthContainer>
      <div className="login-component">
        <h1>The new password was sent to your email</h1>
        <div
          className={`btn-2 continue-btn`}
          onClick={onContinue}>
          Continue
        </div>
      </div>
    </AuthContainer>
  );
}