import { MAIL_CHIMP_ACTION, MAIL_CHIMP_ACTION_FAIL, MAIL_CHIMP_ACTION_SUCCESS } from '../Type';

const INITIAL = {
  mailChimpStatus: "",
  mailChimp_success: false,
  mailChimp_loading: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL, action) => {
  switch (action.type) {
    case MAIL_CHIMP_ACTION: {
      return {
        ...state,
        mailChimp_loading: true,
        mailChimp_success: false,
        mailChimpStatus: "",
      }
    }
    case MAIL_CHIMP_ACTION_FAIL: {
      const { message } = action.data;
      return {
        ...state,
        mailChimp_loading: false,
        mailChimpStatus: message,
        mailChimp_success: false,
      }
    }
    case MAIL_CHIMP_ACTION_SUCCESS: {
      const { message } = action.data;
      return {
        ...state,
        mailChimp_loading: false,
        mailChimpStatus: message,
        mailChimp_success: true,
      }
    }

    default:
      return state;
  }
}