import React from 'react';
import './style.scss';
import { ReactSVG } from 'react-svg'
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import add from 'src/Assets/Images/Landing/add.svg';
import { Review } from 'src/Components';
import { toggleReviewModal } from 'src/Redux/Actions';

export default function ReviewModal() {
  const dispatch = useDispatch();

  const {
    isReviewModal, reviewInfo
  } = useSelector((state) => state.Modal);

  const closeModal = () => {
    dispatch(toggleReviewModal(false));
  };

  return (
    <Modal show={isReviewModal} className="feedback-modal-component">
      <div className="feedback-content v-r">
        <div className="close-btn">
          <ReactSVG src={add} className='icon-close' onClick={closeModal} />
        </div>
        {!_.isEmpty(reviewInfo) &&
          <Review
            info={{ ...reviewInfo }}
            index={reviewInfo.index}
            className="sub-feedback"
          />}
      </div>
    </Modal>
  );
}